// @flow
import * as React from 'react';
import cx from 'classnames';
import classes from './style.module.scss';

type Props = {
  id?: string,
  'data-testid'?: string,
  className?: string,
  value: string,
  type?: string,
  disabled?: boolean,
  readOnly?: boolean,
  onChange: (SyntheticInputEvent<EventTarget>) => void,
};

const TextInput = ({
  onChange,
  className = '',
  id,
  value,
  type = 'text',
  disabled = false,
  readOnly = false,
  ...props
}: Props) => {
  const nonNullValue = value || '';

  return (
    <input
      className={cx(classes.textInput, className)}
      type={type}
      id={id}
      onChange={onChange}
      value={nonNullValue}
      data-testid={props['data-testid']}
      disabled={disabled}
      readOnly={readOnly}
    />
  );
};

export default TextInput;
