// @flow
import axios from 'axios';

const options = {
  // $FlowFixMe
  baseURL: process.env.REACT_APP_ROUNDUP_API_URL,
  timeout: 5000,
  headers: { 'Content-Type': 'application/json' },
};

const apiClient = axios.create(options);

let jwt = null;

export const setJwt = (token: string) => {
  jwt = token;
};

apiClient.interceptors.request.use(
  config => {
    if (jwt !== null) {
      // $FlowFixMe
      config.headers.Authorization = `Bearer ${jwt}`;
    }
    return config;
  },
  error => Promise.reject(error)
);

export default apiClient;
