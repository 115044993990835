// @flow
import * as React from 'react';

export const PUBLIC_NAV_ITEMS = {
  AUCTION: 'nav.auction',
  CATALOG: 'nav.catalog',
};

export const BUYER_NAV_ITEMS = {
  ...PUBLIC_NAV_ITEMS,
  PURCHASES: 'nav.purchases',
};

export const ADMIN_NAV_ITEMS = {
  ...PUBLIC_NAV_ITEMS,
  ...BUYER_NAV_ITEMS,
  ADMIN_PAGE: 'nav.adminPage',
  USERS: 'nav.users',
};

type Props = {
  children: React.Node,
};

type State = {
  activeNavigationItem: string,
  navigate: string => void,
};

const NavigationContext = React.createContext();

class NavigationProvider extends React.PureComponent<Props, State> {
  navigate = (navItem: string) => this.setState({ activeNavigationItem: navItem });

  state = {
    activeNavigationItem: PUBLIC_NAV_ITEMS.AUCTION,
    navigate: this.navigate,
  };

  render() {
    return <NavigationContext.Provider value={this.state}>{this.props.children}</NavigationContext.Provider>;
  }
}

// $FlowFixMe
export function withNavigation(Component) {
  // $FlowFixMe
  return function NavigableComponent(props) {
    return (
      <NavigationContext.Consumer>
        {/*  $FlowFixMe */}
        {({ activeNavigationItem, navigate }) => (
          <Component {...props} activeNavigationItem={activeNavigationItem} navigate={navigate} />
        )}
      </NavigationContext.Consumer>
    );
  };
}

export default NavigationProvider;
