// @flow
import * as React from 'react';
import { toast } from 'react-toastify';
import cx from 'classnames';

import Text from 'components/Text';
import Button from 'components/Button';
import TextInput from 'components/TextInput';
import Logo from 'components/Logo';
import * as auctionService from 'services/auction';
import { playAudio, stopAudio } from 'utils/audioPlayer';

import classes from './style.module.scss';

const LoginCard = ({ title, children }) => (
  <div className={classes.loginCard}>
    <h2>
      <Text t={title} />
    </h2>
    {children}
  </div>
);

type State = {
  username: string,
  password: string,
  auctionId: number,
};

type Props = {
  // $FlowFixMe
  logIn: (string, string, number) => any,
  // $FlowFixMe
  logInAsSpectator: number => any,
};

class Login extends React.Component<Props, State> {
  state = {
    username: '',
    password: '',
    auctionId: 0,
  };

  componentDidMount() {
    const auctionId = auctionService.getAuctionId();
    if (auctionId) {
      this.setState({
        auctionId,
      });
    }
  }

  handleLogInAsBuyer = event => {
    playAudio();
    if (event) {
      event.preventDefault();
    }
    const { username, password, auctionId } = this.state;
    this.props.logIn(username, password, auctionId).catch(() => {
      stopAudio();
      toast.error(<Text t="login.badCredentials" />);
    });
  };

  handleLogInAsSpectator = () => {
    playAudio();
    this.props.logInAsSpectator(this.state.auctionId).catch(() => {
      stopAudio();
      toast.error(<Text t="login.badCredentials" />);
    });
  };

  handleUsernameChange = (e: SyntheticInputEvent<EventTarget>) => {
    this.setState({ username: e.target.value });
  };

  handlePasswordChange = (e: SyntheticInputEvent<EventTarget>) => {
    this.setState({ password: e.target.value });
  };

  render() {
    const { username, password } = this.state;

    return (
      <div className={classes.login}>
        <div className={classes.loginContent}>
          <div>
            <Logo />
          </div>
          <LoginCard title="login.mustLogIn">
            <form onSubmit={this.handleLogInAsBuyer} className={classes.loginForm}>
              <div className={classes.labelFieldRow}>
                <label htmlFor="usernameInput">
                  <Text t="login.username" />
                </label>
                <TextInput
                  id="usernameInput"
                  data-testid="usernameInput"
                  className={classes.input}
                  value={username}
                  onChange={this.handleUsernameChange}
                />
              </div>

              <div className={classes.labelFieldRow}>
                <label htmlFor="passwordInput">
                  <Text t="login.password" />
                </label>
                <TextInput
                  id="passwordInput"
                  data-testid="passwordInput"
                  className={classes.input}
                  type="password"
                  value={password}
                  onChange={this.handlePasswordChange}
                />
              </div>

              <div className={cx(classes.buttonRow, classes.registeredLogInButtonWrapper)}>
                <Button
                  htmlType="submit"
                  data-testid="enterAsBuyerBtn"
                  className={classes.cardButton}
                  onClick={this.handleLogInAsBuyer}
                >
                  <Text t="login.enterAsBuyer" />
                </Button>
              </div>
            </form>
          </LoginCard>
          <LoginCard title="login.spectatorsOnly">
            <div className={classes.buttonRow}>
              <Button className={classes.cardButton} onClick={this.handleLogInAsSpectator}>
                <Text t="login.enterAsSpectator" />
              </Button>
            </div>
          </LoginCard>
          <div className={classes.register}>
            <h3>
              <Text t="login.needBuyerAccount" />
            </h3>
            <Text t="login.toRegister" />
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
