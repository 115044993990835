// import 'core-js/stable';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import * as React from 'react';
import * as Sentry from '@sentry/browser';

import App from 'containers/App';

Sentry.init({
  dsn: 'https://22c04b1a21664edc98eb6bfbe61dea15@sentry.io/1450263',
});

const renderApp = Component => {
  const target = document.getElementById('root');
  if (!target) return;

  ReactDOM.render(<Component />, target);
};

renderApp(App);
