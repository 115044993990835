// @flow
import * as React from 'react';
import hoistNonReactStatic from 'hoist-non-react-statics';

import type { User } from 'domain/User';
import * as authService from 'services/auth';
import { stopAudio } from 'utils/audioPlayer';
import Login from './Login';

const initialState = { user: null, token: null };

export const AuthenticationContext = React.createContext(initialState);

export function withCredentials(Component) {
  function AuthenticatedComponent(props) {
    return (
      <AuthenticationContext.Consumer>
        {({ user, token }) => <Component {...props} user={user} token={token} />}
      </AuthenticationContext.Consumer>
    );
  }
  return hoistNonReactStatic(AuthenticatedComponent, Component);
}

export const ROLES = {
  ADMIN: 'admin',
  SPECTATOR: 'spectator',
  BUYER: 'buyer',
  ALAMO_BUYER: 'alamoBuyer',
};

export const hasRole = (userRoles: Role[], wantedRole: string) =>
  userRoles.some(userRole => userRole.roleName === wantedRole);

type RoleProps = {
  user: User,
  children: React.Node,
};

export const Spectator = withCredentials(({ user, children }: RoleProps) => {
  if (hasRole(user.roles, ROLES.SPECTATOR)) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  return null;
});

export const Buyer = withCredentials(({ user, children }: RoleProps) => {
  if (!hasRole(user.roles, ROLES.BUYER) && !hasRole(user.roles, ROLES.ALAMO_BUYER)) {
    return null;
  }

  return <React.Fragment>{children}</React.Fragment>;
});

export const Admin = withCredentials(({ user, children }: RoleProps) => {
  if (!hasRole(user.roles, ROLES.ADMIN)) {
    return null;
  }

  return <React.Fragment>{children}</React.Fragment>;
});

export type AuthenticationStore = {
  user: null | User,
  token: null | string,
};

type State = AuthenticationStore;

type Props = {
  children?: React.Node,
};

class AuthenticationProvider extends React.Component<Props, State> {
  state = {
    user: null,
    token: null,
  };

  storeCredentials = (user: User, token: string) =>
    this.setState({ user, token }, () => {
      authService.setJwtToClient(token);
    });

  logIn = (username: string, password: string, auctionId: number) =>
    authService.logIn(username, password, auctionId).then(({ data }) => {
      this.storeCredentials(data.user, data.token);
      if (hasRole(data.user.roles, ROLES.ADMIN)) {
        stopAudio();
      }
    });

  logInAsSpectator = (auctionId: number) =>
    authService.logInAsSpectator(auctionId).then(({ data }) => this.storeCredentials(data.user, data.token));

  render() {
    return (
      <AuthenticationContext.Provider value={this.state}>
        {this.state.user ? this.props.children : <Login logIn={this.logIn} logInAsSpectator={this.logInAsSpectator} />}
      </AuthenticationContext.Provider>
    );
  }
}

export default AuthenticationProvider;
