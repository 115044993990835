// @flow
import * as React from 'react';
import io from 'socket.io-client';
import { toast } from 'react-toastify';

import * as auctionService from 'services/auction';
import { withCredentials, hasRole, ROLES } from 'containers/AuthenticationProvider';
import type { User } from 'domain/User';
import type { Lot } from 'domain/Lot';
import type { Auction } from 'domain/Auction';

// $FlowFixMe
import gunSound from 'sounds/ricochet.mp3';

import classes from './style.module.scss';

const EVENT_TYPES = {
  LIVE_USERS: 'liveUsers',
  LOTS: 'lots',
  AUCTION: 'auction',
  CURRENT_LOT: 'currentLot',
  BID_PLACED: 'bidPlaced',
  LOT_STATUS_CHANGED: 'lotStatusChanged',
};

type Props = {
  token: string,
  children: React.Node,
};

export type Store = {
  liveUsers: User[],
  lots: Lot[],
  auction: Auction | null,
  currentLot: Lot | null,
};

type State = {
  store: Store,
};

const RealtimeContext = React.createContext();

class RealtimeDataProvider extends React.PureComponent<Props, State> {
  audioPlayer: HTMLAudioElement;

  constructor() {
    super();
    // $FlowFixMe
    this.audioPlayer = new Audio(gunSound);
  }

  state = {
    store: {
      liveUsers: [],
      lots: [],
      auction: null,
      currentLot: null,
      showSoldMessage: false,
    },
  };

  componentDidMount() {
    const { token } = this.props;

    const auctionId = String(auctionService.getAuctionId());
    // $FlowFixMe
    const socket = io(process.env.REACT_APP_ROUNDUP_API_URL, {
      path: '/api/realtime',
      query: { auctionId },
      transportOptions: {
        polling: {
          extraHeaders: {
            Authorization: `Bearer ${token}`,
          },
        },
      },
    });
    socket.on(EVENT_TYPES.LIVE_USERS, this.handleLiveUsersEvent);
    socket.on(EVENT_TYPES.AUCTION, this.handleAuctionEvent);
  }

  handleLiveUsersEvent = (liveUsers: User[]) => {
    this.setState(state => ({
      ...state,
      store: { ...state.store, liveUsers },
    }));
  };

  handleAuctionEvent = ({ auction, lots, currentLot, meta }) => {
    if (meta && meta.event) {
      switch (meta.event) {
        case EVENT_TYPES.LOT_STATUS_CHANGED:
          this.handleLotStatusChangeEvent(meta);
          break;
        case EVENT_TYPES.BID_PLACED:
          this.playBangSound();
          break;
        default:
          break;
      }
    }

    this.setState(state => ({
      ...state,
      store: { ...state.store, auction, lots, currentLot },
    }));
  };

  /**
   * We want the sold lot to stay on screen for a couple of seconds. Backend will
   * notify that the lot was sold, and update the auction a couple of seconds later.
   * We can show the message during that time. Backend also notifies us to clear the message.
   */
  handleLotStatusChangeEvent = ({ lot, status }) => {
    const { user } = this.props;

    if (status === 'sold') {
      this.setState(state => ({
        ...state,
        store: { ...state.store, showSoldMessage: true },
      }));
      return;
    }

    if (status === 'clearSold') {
      this.setState(state => ({
        ...state,
        store: { ...state.store, showSoldMessage: false },
      }));
      return;
    }

    const userIsNotAdmin = !hasRole(user.roles, ROLES.ADMIN);
    if (userIsNotAdmin) {
      toast.info(`Lot ${lot.lotNumber} was ${status}!`, {
        autoClose: 5000,
        position: toast.POSITION.TOP_CENTER,
        className: classes.lotStatusToast,
      });
    }
  };

  playBangSound = () => {
    if (this.audioPlayer) {
      this.audioPlayer.play();
    }
  };

  render() {
    return <RealtimeContext.Provider value={this.state}>{this.props.children}</RealtimeContext.Provider>;
  }
}

// $FlowFixMe
export const withRealtimeData = mapStateToProps => Component => {
  // $FlowFixMe
  return function RealtimeComponent(props) {
    return (
      <RealtimeContext.Consumer>
        {/*  $FlowFixMe */}
        {({ store }) => <Component {...props} {...mapStateToProps(store, props)} />}
      </RealtimeContext.Consumer>
    );
  };
};

export default withCredentials(RealtimeDataProvider);
