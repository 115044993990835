// @flow
import * as React from 'react';
import { ToastContainer } from 'react-toastify';
import { DragDropContextProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

import AuthenticationProvider from 'containers/AuthenticationProvider';
import RealtimeDataProvider from 'containers/RealtimeDataProvider';
import NavigationProvider from 'containers/NavigationProvider';

import ErrorBoundary from 'components/ErrorBoundary';
import AppError from 'components/AppError';
// $FlowFixMe
import 'react-toastify/dist/ReactToastify.min.css';
import classes from './style.module.scss';

const MainLayout = React.lazy(() => import('components/MainLayout'));

const App = () => {
  return (
    <ErrorBoundary fallbackComponent={AppError}>
      <ToastContainer toastClassName={classes.toast} autoClose={4000} />
      <DragDropContextProvider backend={HTML5Backend}>
        <main>
          <AuthenticationProvider>
            <RealtimeDataProvider>
              <NavigationProvider>
                <React.Suspense fallback={<div />}>
                  <MainLayout />
                </React.Suspense>
              </NavigationProvider>
            </RealtimeDataProvider>
          </AuthenticationProvider>
        </main>
      </DragDropContextProvider>
    </ErrorBoundary>
  );
};

export default App;
