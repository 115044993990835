// @flow
import * as React from 'react';
import cx from 'classnames';
import classes from './style.module.scss';

type Props = {
  onClick: (SyntheticEvent<HTMLButtonElement>) => void,
  className?: string,
  disabled?: boolean,
  children: React.Node,
  'data-testid'?: string,
  type?: string,
  htmlType?: string,
  size?: string,
};

const Button = ({
  onClick,
  className,
  disabled = false,
  children,
  htmlType = 'button',
  type = Button.TYPE.DEFAULT,
  size = Button.SIZE.DEFAULT,
  ...props
}: Props) => (
  <button
    className={cx(
      classes.button,
      {
        [classes.primary]: type === Button.TYPE.PRIMARY,
        [classes.secondary]: type === Button.TYPE.SECONDARY,
        [classes.danger]: type === Button.TYPE.DANGER,
        [classes.large]: size === Button.SIZE.LARGE,
      },
      className
    )}
    type={htmlType}
    onClick={onClick}
    disabled={disabled}
    data-testid={props['data-testid']}
  >
    {children}
  </button>
);

Button.TYPE = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
  DEFAULT: 'DEFAULT',
  DANGER: 'DANGER',
};

Button.SIZE = {
  DEFAULT: 'DEFAULT',
  LARGE: 'LARGE',
};

export default Button;
