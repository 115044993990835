// @flow
import apiClient, { setJwt } from 'utils/apiClient';

export function logIn(username: string, password: string, auctionId: number) {
  return apiClient.post('/auth/private', { username, password, auctionId });
}

export function logInAsSpectator(auctionId: number) {
  return apiClient.post('/auth/spectator', { auctionId });
}

export function setJwtToClient(token: string) {
  setJwt(token);
}
