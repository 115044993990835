// @flow
import qs from 'query-string';
import apiClient from 'utils/apiClient';
import type { User } from 'domain/User';

export function getAuctionId() {
  return Number(qs.parse(window.location.search)['auction_id']) || 0;
}

export const startAuction = (auctionId: number) => {
  return apiClient.post(`api/auctions/${auctionId}/start`);
};

export const resetAuction = (auctionId: number) => {
  return apiClient.post(`api/auctions/${auctionId}/reset`);
};

export const endAuction = (auctionId: number) => {
  return apiClient.post(`api/auctions/${auctionId}/end`);
};

export const skipLot = (auctionId: number, skipToLotId: number) => {
  return apiClient.post(`api/auctions/${auctionId}/skipTo/${skipToLotId}`);
};

export const scratchLot = (auctionId: number, nextLotId: number) => {
  return apiClient.post(`api/auctions/${auctionId}/scratch`, { nextLotId });
};

export const poLot = (auctionId: number, nextLotId: number) => {
  return apiClient.post(`api/auctions/${auctionId}/pullOut`, { nextLotId });
};

export const startBidding = (auctionId: number) => {
  return apiClient.post(`api/auctions/${auctionId}/startBidding`);
};

export const pauseBidding = (auctionId: number) => {
  return apiClient.post(`api/auctions/${auctionId}/pauseBidding`);
};

export const placeBid = (auctionId: number, amount: number) => {
  return apiClient.post(`api/auctions/${auctionId}/bid`, { amount });
};

export const sellLot = (auctionId: number, lotId: number, nextLotId: number, buyer: User, amount: number) => {
  return apiClient.post(`api/auctions/${auctionId}/sell/${lotId}`, { priceSold: amount, nextLotId, buyer });
};

export const decrementAskingPrice = (auctionId: number) => {
  return apiClient.post(`api/auctions/${auctionId}/askingPrice`);
};

export const changeIncrement = (auctionId: number) => {
  return apiClient.post(`api/auctions/${auctionId}/increment`);
};
