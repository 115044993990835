// @flow
import * as React from 'react';
// $FlowFixMe
import strings from './strings.json';

type Props = {
  t: string,
};

const Text = ({ t }: Props) => <React.Fragment>{strings[t]}</React.Fragment>;

export default Text;
