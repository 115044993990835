export const playAudio = () => {
  const audio = document.querySelector('audio');
  if (audio) {
    audio.play();
    audio.muted = false;
  }
};

export const stopAudio = () => {
  const audio = document.querySelector('audio');
  if (audio) {
    audio.pause();
  }
};
